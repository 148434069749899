* {
    font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }
  
  a{
    text-decoration: none;
  }
  
  
  .content-header h1{
    font-size: 1.5rem;
    margin: 0; 
    font-family: 500;
    ;
  }
  
  .text-sm .content-header .breadcrumb {
    line-height: 1.5rem;
  }
  
  .breadcrumb{
    display: flex;
    align-items: stretch;
    margin-bottom: .5rem !important;

  }
  
  
  .btn{
    border: none;
    color: white;
   
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
   
    cursor: pointer;
  }
  
  .card-header{
    background-color: transparent !important;
    
  }
  
  
  .row {
    display: flex !important; /* Use flexbox to arrange items in a row */
    flex-wrap: wrap !important; /* Allow items to wrap to the next line if needed */
  }
  .label-1.dataTables_length {
      display: flex !important;
      align-items: center;
      
    }
    
    .label-1.dataTables_length label {
      margin-right: 10px;
       /* Adjust spacing between label and select */
    }
  
    .label-1.dataTables_length select {
      width: auto !important;
      cursor: pointer;
      
      margin-left: 10px; /* Adjust spacing between select and button */
      margin-right: 10px; /* Adjust spacing between select and button */
      
      display: inline-block; /* Allow select element to adjust its width based on content */
    }
  
    .label-1.dataTables_length option {
      font-weight: normal;
      display: block;
      cursor: pointer;
      min-height: 1.2em;
      padding: 0px 2px 1px;
      white-space: nowrap;
    }
  
  
    .label-input-container {
      display: flex; /* Use flexbox */
      align-items: center;
      padding-bottom: 10px;
      justify-content: flex-end;
      
       /* Align items vertically in the center */
    }
    
    .label-input-container label {
      margin-right: 10px;
      font-weight: 400 !important; /* Add spacing between label and input */
    }
    
    .label-input-container input {
      width: auto; /* Add spacing between label and input */
      cursor: pointer;
    }
  
    .custom-select {
      height: calc(1.8125rem + 2px);
      padding-top: .25rem;
      padding-bottom: .25rem;
      padding-left: .5rem;
      font-size: 75%;
  }
  
  
  .col-md-12,
  .col-md-6 {
    flex: 0 0 auto; /* Flex-grow, flex-shrink, flex-basis */
    max-width: 100%; /* Maximum width */
  }
  
  .dataTables_length,
  .dataTables_filter {
    margin-bottom: 10px; /* Add some space between components */
  }
  
  .label-1.dataTables_length,
  .label-input-container {
    display: flex; /* Use flexbox to arrange components in a row */
    align-items: center; /* Align items vertically */
  }
  

  .mb-2{
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }
  
  .col-sm-12 {
    flex: 0 0 100%; /* Set the column width to 100% */
  }
  
  .col-md-5 {
    display: flex; /* Use flexbox for the first column */
    justify-content: flex-start; /* Align items to the start of the column */
  }
  
  .col-md-7 {
    display: flex; /* Use flexbox for the second column */
    justify-content: flex-end; /* Align items to the end of the column */
  }
  
  .dataTables_info {
    display: flex; /* Use flexbox for the first component */
    align-items: center;
    margin-left: 18px;
    margin-bottom: 23px; /* Align items vertically */
  }
  
  .dataTables_paginate {
    display: flex; /* Use flexbox for the second component */
    align-items: center;
    margin-right: 18px; /* Align items vertically */
  }
  
  
  #datatable { 
    border-collapse: collapse; /* Collapse borders into a single border */
    width: 100%; /* Ensure table takes full width */
  }
  
  #datatable th,
  #datatable td {
    border: 1px solid #ddd; /* Add 1px solid border around each cell */
    padding: 8px; /* Add padding inside cells for spacing */
  }
  
  #datatable th {
    background-color: transparent; /* Optional: Add background color to header cells */
    font-weight: bold; /* Optional: Make header text bold */
  }
  
  #datatable {
    border-collapse: collapse; /* Collapse borders into a single border */
    width: 100%; /* Ensure table takes full width */
    overflow: hidden; /* Hide the scrollbar */
  }


  
  .parent-container {
    display: flex; /* Use flexbox to arrange children in a row */
    justify-content: space-between; /* Add space between the two components */
    flex-direction: row; /* Arrange children in a row */
     /* Wrap items as needed */
     margin-top: 15px;
     margin-bottom: -15px;
     margin-right: 25px;
  }
  
  
    
    @media (max-width: 768px) {
      .label-1 {
        flex-direction: column; /* Change to column layout on smaller screens */
        align-items: flex-start; /* Align items to the start of the column */
      }
    }

    @media only screen and (max-width: 1080px) {
      .mobile-d-1 {
        display: none;
      }
    }
    
    @media only screen and (max-width: 1035px) {
      .mobile-d-2 {
        display: none;
      }
    }
    
    @media only screen and (max-width: 925px) {
      .mobile-d-3 {
        display: none;
      }
    }
    
    @media only screen and (max-width: 827px) {
      .mobile-d-4 {
        display: none;
      }
    }
    
    @media only screen and (max-width: 750px) {
      .mobile-d-5 {
        display: none;
      }
    }
    
    @media only screen and (max-width: 680px) {
      .mobile-d-6 {
        display: none;
      }
    }

    @media only screen and (max-width: 577px) {
      .mobile-d-7 {
        display: none;
      }
    } 

    @media only screen and (max-width: 477px) {
      .mobile-d-8 {
        display: none;
      }
    }
    
    @media only screen and (max-width: 332px) {
      .mobile-d-9 {
        display: none;
      }
    }

    @media only screen and (max-width: 230px) {
      .mobile-d-10 {
        display: none;
      }
    }
      